import { supabase } from '@/supabase';
import { UserService } from '@/utility/UserService';
import { Avatar, Box, Button, Group, ScrollArea, Skeleton, Stack, Text } from '@mantine/core';
import { User } from '@supabase/supabase-js';
import React, { useEffect, useState } from 'react';
import ChevronLeftIcon from '../Icons/ChevronLeftIcon';
import GearIcon from '../Icons/GearIcon';
import PencilSquareIcon from '../Icons/PencilSquareIcon';
import classes from './MobileNav.module.css';
import NavBarSessions from './NavBarSessions';

interface InputProps {
  sessions: ChatSession[];
  sessionsLoading: boolean;
  loadChat: (chatSession: ChatSession) => void;
  toggle: () => void;
  setNewChat: () => void;
  open: boolean;
}

const MobileNav: React.FC<InputProps> = ({
  sessions,
  sessionsLoading,
  loadChat,
  toggle,
  setNewChat,
}) => {
  const [avatar, setAvatar] = useState<{ val: string; isUrl: boolean } | null>({
    val: '??',
    isUrl: false,
  });
  const [user, setUser] = useState<User | null>(null);
  const [divHeight, setDivHeight] = useState(window.screen.height - 35);
  React.useEffect(() => {
    const handleResize = () => {
      setDivHeight(window.innerHeight - 35);
    };

    // Update div height on window resize
    window.addEventListener('resize', handleResize);
    handleResize();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const logout = async (): Promise<void> => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error logging out:', error.message);
    } else {
    }
  };

  const handleLogout = async () => {
    await logout();
    // You might want to redirect the user or update your UI here
  };

  useEffect(() => {
    const userService = UserService.getInstance();

    async function loadUser() {
      const user = await userService.getUser();
      if (user) {
        setUser(user);
        const avatarData = userService.getAvatar();
        setAvatar(avatarData);
      }
    }

    loadUser();
  }, [user]);

  const loadChatWrapper = (session: ChatSession) => {
    toggle();
    loadChat(session);
  };

  return (
    <Box p="sm">
      <Stack style={{ height: `${divHeight}px` }}>
        <Group
          justify="space-between"
          style={{ width: '100%' }}
        >
          {/* Left-aligned: Chevron, Avatar, and Name */}
          <Group justify="flex-start">
            <ChevronLeftIcon onClick={toggle} />
            {!avatar || avatar.val === '??' ? (
              <>
                <Skeleton
                  height={'32px'}
                  circle
                  mb="xl"
                />
              </>
            ) : (
              <>
                {avatar.isUrl ? (
                  <>
                    <Avatar
                      size={'32px'}
                      w={'32px'}
                      src={avatar.val}
                      alt="it's me"
                    />
                  </>
                ) : (
                  <>
                    <Avatar
                      size={'32px'}
                      w={'32px'}
                      color="green"
                      radius="xl"
                    >
                      {avatar.val}
                    </Avatar>
                  </>
                )}
              </>
            )}
            <Text>{user?.email?.replace(/^(.{11}).*?(.{11})$/, '$1...$2')}</Text>
          </Group>

          {/* Right-aligned: Pencil Icon for New Chat */}
          <PencilSquareIcon
            onClick={() => {
              toggle();
              setNewChat();
            }}
            size={22}
          />
        </Group>

        <Text
          fw={600}
          size={'16px'}
          mt={36}
          mb={24}
        >
          {' '}
          Chat History{' '}
        </Text>
        <ScrollArea>
          <NavBarSessions
            sessions={sessions}
            loadChat={loadChatWrapper}
            sessionsLoading={sessionsLoading}
            newChat={setNewChat}
          />
        </ScrollArea>
        <Button
          style={{ minHeight: '48px' }}
          leftSection={
            <>
              <GearIcon />
            </>
          }
          variant="unstyled"
          className={classes.logout}
          onClick={logout}
          fullWidth
        >
          {' '}
          Log Out
        </Button>
      </Stack>
    </Box>
  );
};

export default MobileNav;
