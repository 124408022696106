import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Login from './components/Login/Login';
import { ForgotPassword } from './pages/ForgotPassword.page';
import { HomePage } from './pages/Home.page';
import { PostSignupPage } from './pages/PostSignupPage';
import { VerificationEmailSent } from './pages/VerificationEmailSent.page';

interface RouterProps {
  session: any; // Use proper typing if needed
}

interface ProtectedRouteProps {
  session: any; // Use proper typing if needed
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ session }) => {
  return session ? <Outlet /> : <Navigate to="/login" />;
};

//user is logged in, don't let them access pages like signup or login.
const ReverseProtectedRoute: React.FC<ProtectedRouteProps> = ({ session }) => {
  return !session ? <Outlet /> : <Navigate to="/" />;
};

export const Router: React.FC<RouterProps> = ({ session }) => (
  <BrowserRouter>
    <Routes>
      {/* Protected routes */}
      <Route element={<ProtectedRoute session={session} />}>
        <Route
          path="/"
          element={<HomePage />}
        />
      </Route>

      <Route element={<ReverseProtectedRoute session={session} />}>
        <Route
          path="/login"
          element={<Login />}
        />
        <Route
          path="/google"
          element={<PostSignupPage />}
        />
        <Route
          path="/finishsignup"
          element={<PostSignupPage />}
        />
        <Route
          path="/signupsent"
          element={<VerificationEmailSent />}
        />
      </Route>
      <Route
        path="/forgotpassword"
        element={<ForgotPassword />}
      />
      <Route
        path="/changepassword"
        element={<ForgotPassword />}
      />
    </Routes>
  </BrowserRouter>
);
