import { LLMModel } from '@/utility/Utilities';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type ModelStoreProps = {
  selectedModel?: LLMModel;
  setSelectedModel: (model: LLMModel) => void;
};

const useModelStore = create(
  persist<ModelStoreProps>(
    (set) => ({
      selectedModel: undefined,

      setSelectedModel: (model: LLMModel) => {
        set({ selectedModel: model });
      },
    }),
    {
      name: 'model-store',
    }
  )
);

export default useModelStore;
