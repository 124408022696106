import { Box } from '@mantine/core';
import React, { forwardRef, MouseEventHandler } from 'react';

interface ChevronLeftIconProps extends React.HTMLAttributes<HTMLDivElement> {
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const ChevronLeftIcon = forwardRef<HTMLDivElement, ChevronLeftIconProps>((props, ref) => {
  return (
    <Box
      style={{ cursor: 'pointer' }}
      ref={ref}
      {...props}
      w={22}
      h={22}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={22}
        height={22}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6"
        style={{ transform: 'rotate(90deg)' }} // Rotate the icon 90 degrees to point left
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m19.5 8.25-7.5 7.5-7.5-7.5"
        />
      </svg>
    </Box>
  );
});

export default ChevronLeftIcon;
