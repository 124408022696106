import { supabase, SUPABASE_HOST_API } from '@/supabase';
import {
  Box,
  Button,
  Center,
  Divider,
  Grid,
  Group,
  Loader,
  Modal,
  PasswordInput,
  Stack,
  TextInput,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { ConnectButton, useConnectModal } from '@rainbow-me/rainbowkit';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SiweMessage } from 'siwe';
import { useAccount, useSignMessage } from 'wagmi';
import classes from '../../app.module.css';
import eth from '../../assets/eth.png';
import google from '../../assets/google.png';
import logo from '../../assets/logonewchat.png';

export default function Login() {
  const { address, isConnected } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const [nonceRSV, setNonce] = useState('');
  const [loginState, setLoginState] = useState('');
  const [email, setEmail] = useState(''); // State for email
  const [password, setPassword] = useState(''); // State for password
  const [signupMode, setSignupMode] = useState(false);
  const isFirstRender = useRef(true); // Ref to track if it's the first render
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the first render
      isFirstRender.current = false;
      return;
    }

    if (address === undefined) return;

    login();
  }, [address]); // Empty array ensures it only runs on component mount

  const { openConnectModal } = useConnectModal();

  const [opened, { open, close }] = useDisclosure(false);

  const getNonce = async () => {
    const response = await fetch(`${SUPABASE_HOST_API}/functions/v1/crypto_nonce`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ address }),
    });

    if (!response.ok) {
      notifications.show({
        title: 'Error',
        color: 'red',
        message: 'Could Not Retrieve Security Code',
        position: 'top-center',
      });
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Parse the response as JSON
    const data: { nonce: number } = await response.json();
    setNonce(data.nonce + '');

    return data.nonce + '';
  };

  const login = async () => {
    open();
    setLoginState('Getting Security Code...');

    const nonce = await getNonce();

    setLoginState('Attempting to Sign...');

    console.log('trying to login via supabase');

    if (address && isConnected && nonce && nonce.length > 0) {
      const message = new SiweMessage({
        domain: 'chat.function.network',
        address,
        statement: 'Sign in request from Function',
        uri: 'https://chat.function.network/login',
        version: '1',
        chainId: 1,
        nonce: nonce,
      });

      try {
        const md = message.prepareMessage();
        const signature = await signMessageAsync({
          message: md,
        });

        setLoginState('Logging In...');

        // Send signature and address to your backend for authentication
        const response = await fetch(`${SUPABASE_HOST_API}/functions/v1/crypto_login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ message: md, signature, address }),
        });

        const { auth } = await response.json();

        setLoginState('Communicating with server...');

        if (auth) {
          // Step 2: Set the token into the Supabase client
          const { data, error } = await supabase.auth.setSession({
            access_token: auth,
            refresh_token: auth, // Set refresh_token if you are managing it
          });
          // Log the result of setting the session
        }

        setLoginState('');
        close();
        navigate('/');

        // Store JWT token
      } catch (error) {
        notifications.show({
          title: 'Error',
          color: 'red',
          message: 'Could not login with ETH!',
          position: 'top-center',
        });

        console.error('Error during login:', error);
        setLoginState('');
        close();
      }
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);

  const signUpWithEmail = async () => {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        emailRedirectTo: window.location.origin + '/finishsignup',
      },
    });

    if (error) {
      notifications.show({
        title: 'Error',
        color: 'red',
        message: 'Could not register with that email address.',
        position: 'top-center',
      });
      return { success: false, message: error.message };
    }

    navigate('/signupsent');
    return { success: true, message: 'User registered successfully' };
  };

  const loginWithEmail = async () => {
    if (signupMode) {
      await signUpWithEmail();
      return;
    }

    open();
    setLoginState('Logging in...');

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        console.error('Error logging in:', error);
        notifications.show({
          title: 'Error',
          color: 'red',
          message: 'Invalid email or password',
          position: 'top-center',
        });
        setLoginState('');
      } else {
        setLoginState('');
        navigate('/');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setLoginState('Login failed');
    } finally {
      close();
    }
  };

  const loginWithGoogle = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: window.location.origin + '/google',
        },
      });

      if (error) {
        notifications.show({
          title: 'Error',
          color: 'red',
          message: 'Could Not Login with Google',
          position: 'top-center',
        });
        throw error;
      }
    } catch (error) {
      console.error('Google login error:', error);
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleForgotPassword = async () => {
    setLoginState('Attempting to reset password...');
    open();
    if (!isValidEmail(email)) {
      notifications.show({
        title: 'Error',
        color: 'red',
        message: 'Please enter a valid email address first!',
        position: 'top-center',
      });
      return;
    }

    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: window.location.origin + '/forgotpassword',
      });

      if (error) {
        close();
        console.error('Error sending reset email:', error);
        notifications.show({
          title: 'Error',
          color: 'red',
          message: 'Failed to send forgot-password email!',
          position: 'top-center',
        });
      } else {
        close();

        notifications.show({
          title: 'Success',
          color: 'green',
          message: 'Sent forgot password email',
          position: 'top-center',
        });
      }
    } catch (error) {
      close();

      console.error('Unexpected error:', error);
      notifications.show({
        title: 'Error',
        color: 'red',
        message: 'Failed to send forgot-password email!',
        position: 'top-center',
      });
    }
  };

  return (
    <Grid
      // w={"99%"}
      justify="center" // Centers horizontally
      align="start" // Aligns to the top vertically
      style={{ height: '100vh' }} // Ensures the grid takes the full height of the screen
      gutter={0}
    >
      <Modal
        opened={opened}
        onClose={close}
        withCloseButton={false}
      >
        <Group justify="center">
          {' '}
          <Loader />
          <Box>
            <Center>{loginState}</Center>
          </Box>
        </Group>
      </Modal>
      <Grid.Col
        span={{ base: 12, md: 2.7, lg: 2.7, sm: 4, xs: 11.5 }}
        style={{ maxWidth: '340px' }}
      >
        <div>
          <div className={classes.logoContainer}>
            <Stack
              mt={isMobile ? 0 : 120}
              justify="center"
              align="center"
            >
              <img
                src={logo}
                alt="Logo"
                height={'40px'}
                className={classes.logo}
              />
              <p className={classes.text}>{signupMode ? 'Sign Up' : 'Log In'}</p>
            </Stack>
          </div>

          <TextInput
            label="Email Address"
            classNames={{ label: classes.label, input: classes.input }}
            onKeyDown={(e) => e.key === 'Enter' && loginWithEmail()} // Handle enter key
            onChange={handleEmailChange}
            className={classes.textInput}
          />
          <PasswordInput
            label="Password"
            classNames={{ label: classes.label, input: classes.input }}
            onKeyDown={(e) => e.key === 'Enter' && loginWithEmail()} // Handle enter key
            onChange={handlePasswordChange}
            className={classes.textInput}
          />

          {!signupMode && (
            <a
              className={classes.forgot}
              onClick={handleForgotPassword}
            >
              Forgot your password?
            </a>
          )}

          <div>
            <Button
              variant="unstyled"
              onClick={loginWithEmail}
              className={classes.logInBtn}
            >
              {' '}
              {signupMode ? 'Sign Up' : 'Log In'}
            </Button>
          </div>

          {signupMode ? (
            <div className={classes.signUp}>
              <span>
                Already have an Account?{' '}
                <a
                  onClick={() => {
                    setSignupMode(false);
                  }}
                >
                  Log-In
                </a>
              </span>
            </div>
          ) : (
            <div className={classes.signUp}>
              <span>
                Don't have an account?{' '}
                <a
                  onClick={() => {
                    setSignupMode(true);
                  }}
                >
                  Sign Up
                </a>
              </span>
            </div>
          )}

          <Divider
            my="xs"
            label="or"
            style={{ color: 'red' }}
            labelPosition="center"
          />

          <div>
            <Button
              onClick={loginWithGoogle}
              variant="unstyled"
              className={classes.socialLogin}
              leftSection={
                <>
                  <img
                    height={24}
                    src={google}
                  ></img>
                </>
              }
            >
              {' '}
              Continue with Google{' '}
            </Button>
          </div>

          {address ? (
            <div>
              <Button
                variant="unstyled"
                className={classes.socialLogin}
                onClick={login}
                leftSection={
                  <div onClick={(e) => e.stopPropagation()}>
                    {' '}
                    {/* Prevent bubbling */}
                    <ConnectButton
                      chainStatus="none"
                      showBalance={false}
                      label=""
                    />
                  </div>
                }
              >
                {' '}
                Continue with Wallet{' '}
              </Button>
            </div>
          ) : (
            <div>
              <Button
                variant="unstyled"
                className={classes.socialLogin}
                onClick={openConnectModal}
                leftSection={
                  <>
                    <img
                      height={24}
                      src={eth}
                    ></img>
                  </>
                }
              >
                {' '}
                Continue with Wallet{' '}
              </Button>
            </div>
          )}
        </div>
      </Grid.Col>
    </Grid>
  );
}
