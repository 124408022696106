import { Box, Center, Grid, Paper } from '@mantine/core';
import React, { useRef, useState } from 'react';
import PaperSendIcon from '../Icons/PaperSendIcon';
import classes from './UserInput.module.css';

interface InputProps {
  handleSend: (message: string) => Promise<void>;
}

const ChatBox: React.FC<InputProps> = ({ handleSend }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const textBinderRef = useRef<HTMLDivElement>(null);

  const [message, setMessage] = useState<string>('');

  const handleSendWrapper = (message: string) => {
    if (message === '' || message === undefined) {
      return;
    }

    handleInputChange({
      target: {
        value: '',
      },
    } as React.ChangeEvent<HTMLTextAreaElement>);

    handleSend(message);
  };

  function getLineNumber(scrollHeight: number) {
    const firstHeight = 20; // Initial scroll height
    const increment = 18; // Increment per line
    // Calculate the line number based on the given scrollHeight
    const lineNumber = Math.floor((scrollHeight - firstHeight) / increment) + 1;
    if (lineNumber <= 0) return 1;

    return lineNumber;
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);

    if (e.target.value === '') {
      if (textareaRef.current && textBinderRef.current) {
        textareaRef.current.style.height = '18.75px';
        textBinderRef.current.style.height = '64px';
        return;
      }
    }
    if (textBinderRef.current && textareaRef.current) {
      textareaRef.current.style.height = 'auto';

      const ln = getLineNumber(textareaRef.current.scrollHeight);
      const scrollHeight = 64 - 18.75 + ln * 18.75;
      const scrollHeight2 = ln * 18.75;
      const maxHeight2 = 4 * 18.75;

      //  = `${Math.min(scrollHeight, maxHeight)}px`;
      const textHeight = Math.min(scrollHeight2, maxHeight2);
      textareaRef.current.style.height = textHeight + 'px';
      textBinderRef.current.style.height = textHeight + 45.25 + 'px';

      if (scrollHeight > 132) {
        textareaRef.current.style.overflowY = 'auto';
      } else {
        textareaRef.current.style.overflowY = 'hidden';
      }
    }

    // if (textareaRef.current) {
    //     // Reset height to auto to calculate new scroll height
    //     textareaRef.current.style.height = 'auto';

    //     // Set the height to the scrollHeight or limit to 6 lines (~96px)
    //     const scrollHeight = Math.min(textareaRef.current.scrollHeight, 18.75);
    //     const maxHeight = 6 * 18.75; // Assuming 24px line-height for 6 lines
    //     textareaRef.current.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
    //     if (scrollHeight > 132) {
    //         textareaRef.current.style.overflowY = 'auto';
    //     } else {
    //         textareaRef.current.style.overflowY = 'hidden';
    //     }
    // }
  };

  return (
    <div style={{ flex: '0 0 97px' }}>
      <Box style={{ maxWidth: 768, margin: 'auto', padding: 16 }}>
        <Grid align="center">
          <Grid.Col
            span="auto"
            style={{ position: 'relative' }}
          >
            <div
              className={classes.chatBinder}
              ref={textBinderRef}
            >
              <textarea
                ref={textareaRef}
                className={classes.userchat}
                rows={1} // Start with a single row
                // style={{ overflowY: 'auto' }} // Enable vertical scroll if necessary

                placeholder="Message Function..."
                // variant={'filled'}
                value={message}
                onChange={handleInputChange}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault(); // Prevents adding a new line
                    handleSendWrapper(message);
                    setMessage('');
                  }
                }}
              />
            </div>
            <Paper
              style={{
                position: 'absolute',
                right: '20px',
                bottom: '20px',
                // top: '50%',
                // transform: 'translateY(-50%)', // Vertically center
                width: '40px',
                height: '40px',
              }}
              variant="filled"
              bg={'white'}
              radius="xl"
            >
              <Center>
                <PaperSendIcon
                  data-disabled={message?.length <= 0 ? 'true' : 'false'}
                  onClick={() => {
                    handleSendWrapper(message);
                    setMessage('');
                  }}
                />
              </Center>
            </Paper>
          </Grid.Col>
          {/* <Grid.Col span="content">

                    </Grid.Col> */}
        </Grid>
      </Box>
    </div>
  );
};

export default ChatBox;
