import ChatContent from '@/components/Chat/ChatContent';
import { ChatHeader } from '@/components/Chat/ChatHeader';
import EmptyChat from '@/components/Chat/EmptyChat';
import ChatBox from '@/components/Chat/UserInput';
import PencilSquareIcon from '@/components/Icons/PencilSquareIcon';
import SideBarIcon from '@/components/Icons/SideBarIcon';
import MobileNav from '@/components/NavBar/MobileNav';
import NavBarSessions from '@/components/NavBar/NavBarSessions';
import NewChatButton from '@/components/NavBar/NewChatButton';
import useModelStore from '@/store/modelStore';
import { supabase, SUPABASE_HOST_API } from '@/supabase';
import LottieAnimation from '@/utility/LottieAnimation';
import {
  convertToOAIMessages,
  supaBaseGetChatMessagesBySession,
  supaBaseGetSessions,
  supaBaseWriteToChatMessage,
  supaBaseWriteToChatSession,
} from '@/utility/Utilities';
import {
  AppShell,
  Box,
  Burger,
  Center,
  Grid,
  Group,
  Paper,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useEffect, useRef, useState } from 'react';
import powered from '../assets/powered.png';
import useReloadStore, { ReloadStoreProps } from '../store/reloadStore';

export function HomePage() {
  // Sidebar state
  const [opened, { toggle }] = useDisclosure();

  // Chat state
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [chatId, setChatId] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [modeLoading, setModelLoading] = useState(false);
  const { selectedModel } = useModelStore();

  // Sessions state
  const [sessions, setSessions] = useState<ChatSession[]>([]);

  // Layout state
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [divHeight, setDivHeight] = useState(window.innerHeight - 35);

  // Ref to manage AbortController
  const abortControllerRef = useRef<AbortController | null>(null);

  // Chat versioning to manage multiple chats
  const [chatVersion, setChatVersion] = useState<number>(0);

  // Ref for message queue and processing flag
  const messageQueue = useRef<(ChatMessage & { done: boolean; chatVersion: number })[]>([]);
  const processing = useRef(false);

  // Fetch chat sessions from the server
  const fetchSessions = async () => {
    try {
      const sessionsFromSupaBase = await supaBaseGetSessions();

      if (!sessionsFromSupaBase || !sessionsFromSupaBase.length) {
        throw new Error('Error retrieving sessions');
      }

      const mappedSessions: ChatSession[] = sessionsFromSupaBase.map((rawSession) => ({
        id: rawSession.id,
        createdAt: rawSession.created_at,
        title: rawSession.title,
        lastModelUsed: rawSession.last_model_used,
        chatMessages: [], // Unused?
      }));

      // Sort the sessions by createdAt (ascending order)
      const sortedMappedSessions = mappedSessions.sort((a, b) => {
        return new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime();
      });

      setSessions(sortedMappedSessions);
    } catch (error) {
      console.error('Error fetching sessions:', error);
    }
  };

  const reloadFlag = useReloadStore((state) => (state as ReloadStoreProps).reloadSessions);

  // Fetch sessions on component mount and when reloadFlag changes
  useEffect(() => {
    fetchSessions();
  }, [reloadFlag]);

  // Handle window resize to adjust chat container height
  useEffect(() => {
    const handleResize = () => {
      setDivHeight(window.innerHeight - 35);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Cleanup any ongoing fetch requests on component unmount
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  // Utility function to generate UUIDs
  function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  // Function to summarize the initial prompt for new sessions
  const summarizePrompt = async (prompt: string) => {
    try {
      if (!selectedModel) {
        return;
      }
      const { data: userData } = await supabase.auth.getSession();
      const res = await fetch(`${SUPABASE_HOST_API}/functions/v1/chatgpt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData?.session?.access_token}`,
        },
        body: JSON.stringify({
          model: selectedModel.internal_name,
          messages: [
            { role: 'user', content: `Summarize the following in 2-4 words: "${prompt}"` },
          ],
          stream: false,
        }),
      });

      if (!res.ok) {
        throw new Error('Failed to fetch response from API');
      }

      const data = await res.json();

      if (data.choices && data.choices.length > 0) {
        const choice = data.choices[0];
        const choiceMessageBody = choice.message;
        if (choiceMessageBody.content) {
          let choiceMessage = choiceMessageBody.content;
          choiceMessage = choiceMessageBody.content.replace('<|eot_id|>', '');
          return choiceMessage.split('\n')[0].slice(0, 39).trim().replaceAll('"', '');
        }
      }
      throw new Error('Failed to summarize prompt');
    } catch (error) {
      console.error('Error:', error);
      throw new Error('Failed to summarize prompt');
    }
  };

  // Load chat messages from the server for a given chat ID
  const loadSessionFromServer = async (passedChatId: string) => {
    if (!passedChatId || passedChatId === '') return;

    const fetchMessages = async () => {
      try {
        const messagesFromSupaBase = await supaBaseGetChatMessagesBySession(passedChatId);

        if (!messagesFromSupaBase) {
          throw new Error('No messages found');
        }

        // Map the incoming data to your ChatMessage interface
        const chatMessages: ChatMessage[] = messagesFromSupaBase.map((message) => ({
          id: message.id,
          createdAt: message.created_at,
          message: {
            role: message.message.role,
            content: message.message.content,
          },
          chatSession: message.chat_session,
        }));

        // Sort the messages by createdAt (ascending order)
        const sortedChatMessages = chatMessages.sort((a, b) => {
          return new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime();
        });

        setMessages(sortedChatMessages);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  };

  // Handle user input and manage fetch requests
  const handleInputFromUser = async (message: string) => {
    setModelLoading(true);

    // Abort any ongoing fetch request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the current request
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    let confirmedChatUuid = chatId;

    // If no current chat, create a new session
    if (!confirmedChatUuid || confirmedChatUuid === '') {
      setLoading(true);
      confirmedChatUuid = await createNewSessioninServer(message);
      setLoading(false);
    }

    if (!confirmedChatUuid || confirmedChatUuid === '') {
      throw new Error('No chat id found');
    }

    if (!selectedModel) {
      setModelLoading(false);
      return;
    }

    const quid = generateUUID();
    handleSendMessage(
      confirmedChatUuid,
      quid,
      { content: message, role: 'user' },
      true,
      chatVersion
    );

    const history = convertToOAIMessages(messages);
    history.push({ content: message, role: 'user' });

    try {
      const { data: userData } = await supabase.auth.getSession();
      const res = await fetch(`${SUPABASE_HOST_API}/functions/v1/chatgpt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData?.session?.access_token}`,
        },
        body: JSON.stringify({
          model: selectedModel.internal_name,
          messages: history,
          stream: true, // Enables streaming
        }),
        signal: abortController.signal, // Attach the signal to the fetch request
      });

      if (!res.ok) {
        throw new Error('Failed to fetch response from API');
      }

      const reader = res.body?.getReader();
      const decoder = new TextDecoder('utf-8');
      const uuid = generateUUID();

      if (!reader) return;

      let done = false;

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;
        const chunk = decoder.decode(value, { stream: true });

        if (chunk.includes('\n')) {
          const lines = chunk.split('\n').filter((line) => line.trim() !== '');
          for (let line of lines) {
            try {
              line = line.replace('data: ', '');
              line = line.trim();
              const json = JSON.parse(line);

              if (json.choices && json.choices !== 'undefined' && json.choices.length > 0) {
                const choicesJson = json.choices[0];

                if (choicesJson.delta) {
                  const delta = choicesJson.delta;

                  const content: string = delta.content ?? '';

                  if (content === '<|eot_id|>') {
                    continue;
                  }

                  handleSendMessage(
                    confirmedChatUuid,
                    uuid,
                    { role: 'assistant', content },
                    choicesJson.finish_reason,
                    chatVersion
                  );
                }
              }
            } catch {
              // Handle JSON parse errors if needed
            }
          }
        } else {
          try {
            let line = chunk.replace('data: ', '');
            line = line.trim();
            const json = JSON.parse(line);

            if (json.choices && json.choices !== 'undefined' && json.choices.length > 0) {
              const choicesJson = json.choices[0];

              if (choicesJson.delta) {
                const delta = choicesJson.delta;

                if (delta.content) {
                  const content: string = delta.content;

                  if (content === '<|eot_id|>') {
                    continue;
                  }

                  handleSendMessage(
                    confirmedChatUuid,
                    uuid,
                    { role: 'assistant', content },
                    choicesJson.finish_reason,
                    chatVersion
                  );
                }
              }
            }
          } catch {
            // Handle JSON parse errors if needed
          }
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
        // Optionally, you can clear messages or reset states here
      } else {
        console.error('Error:', error);
      }
    } finally {
      setModelLoading(false);
    }
  };

  // Function to send updated messages to the server
  function sendToServer(updatedMessage: ChatMessage) {
    supaBaseWriteToChatMessage(updatedMessage);
  }

  // Create a new chat session on the server
  const createNewSessioninServer = async (firstMessage: string) => {
    const uuid = generateUUID();
    setLoading(true);

    const title = await summarizePrompt(firstMessage);

    const newSession: ChatSession = {
      id: uuid as string,
      title,
      lastModelUsed: '93ab126a-33cf-4bd8-8832-80aa0102c92c', // TODO: Replace with dynamic model ID if available
    };

    await supaBaseWriteToChatSession(newSession);
    fetchSessions();
    setLoading(false);
    setChatId(uuid);
    return uuid;
  };

  /**
   * Handles the entire process of a message being sent to the server.
   * @param confirmedChatUuid - The UUID of the chat session.
   * @param chatUUID - The UUID of the individual message.
   * @param incomingToken - The message content and role.
   * @param done - Flag indicating if the message sending is complete.
   * @param currentVersion - The current chat version to associate messages.
   */
  const handleSendMessage = async (
    confirmedChatUuid: string,
    chatUUID: string,
    incomingToken: ChatMessageMessage,
    done: boolean,
    currentVersion: number
  ) => {
    if (!confirmedChatUuid) return;

    // Add the message along with the current chat version to the queue
    messageQueue.current.push({
      id: chatUUID,
      message: incomingToken,
      chatSession: confirmedChatUuid,
      done,
      chatVersion: currentVersion, // Associate with current chat version
    });

    // Start processing the queue if not already processing
    if (!processing.current) {
      processQueue(confirmedChatUuid, currentVersion);
    }
  };

  // Load a chat session when selected from the nav bar
  const loadChat = (session: ChatSession) => {
    // Abort any ongoing fetch request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }

    // Clear the message queue
    messageQueue.current = [];
    processing.current = false;

    // Increment chat version to associate new messages
    setChatVersion((prev) => prev + 1);

    // Set the new chat ID and load its messages
    setChatId(session.id);
    loadSessionFromServer(session.id);
  };

  // Initialize a new chat session
  const setNewChat = () => {
    // Abort any ongoing fetch request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }

    // Clear the message queue
    messageQueue.current = [];
    processing.current = false;

    // Increment chat version to associate new messages
    setChatVersion((prev) => prev + 1);

    // Reset chat state
    setLoading(true);
    setMessages([]);
    setChatId('');

    // Optionally, you can reset other states or perform additional actions here

    // After a short delay, set loading to false
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  /**
   * Processes the message queue by handling one message at a time.
   * Ensures that only messages corresponding to the current chat version are processed.
   * @param confirmedChatUuid - The UUID of the chat session.
   * @param currentVersion - The current chat version to validate messages.
   */
  const processQueue = (confirmedChatUuid: string, currentVersion: number) => {
    if (messageQueue.current.length === 0) {
      processing.current = false;
      return;
    }

    processing.current = true;

    // Get the first message from the queue
    const messageItem = messageQueue.current.shift();
    if (messageItem) {
      // Check if the message belongs to the current chat version
      if (messageItem.chatVersion !== currentVersion) {
        // Ignore messages from previous chats
        return processQueue(confirmedChatUuid, currentVersion);
      }

      // Process the message
      setMessages((prevMessages) => {
        const existingMessageIndex = prevMessages.findIndex(
          (message) => message.id === messageItem.id
        );

        if (existingMessageIndex !== -1) {
          // Create a new array with the updated message
          const updatedMessages = [...prevMessages];
          // Update the content
          const newMessage = {
            ...updatedMessages[existingMessageIndex],
            message: {
              ...updatedMessages[existingMessageIndex].message,
              content:
                updatedMessages[existingMessageIndex].message.content + messageItem.message.content,
            },
          };
          updatedMessages[existingMessageIndex] = newMessage;
          if (messageItem.done) {
            sendToServer(newMessage);
          }
          return updatedMessages;
        } else {
          // Add the new message if it doesn't exist
          const newMessages = [
            ...prevMessages,
            {
              id: messageItem.id,
              chatSession: confirmedChatUuid,
              message: messageItem.message,
            },
          ];
          if (messageItem.done) {
            sendToServer(messageItem);
          }
          return newMessages;
        }
      });
    }

    // Continue processing the next message in the queue
    setTimeout(() => processQueue(confirmedChatUuid, currentVersion), 0);
  };

  return (
    <AppShell
      header={{ height: 0 }}
      navbar={{ width: opened ? 64 : 300, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      padding="md"
      styles={{
        main: {
          border: 'none', // Remove border from main content
        },
        header: {
          border: 'none', // Remove border from header
        },
        navbar: {
          border: 'none', // Remove border from navbar
        },
      }}
    >
      {/* Header Section */}
      <AppShell.Header>
        <Group
          h="100%"
          px="md"
        >
          <Burger
            mt={'20px'}
            opened={opened}
            onClick={toggle}
            hiddenFrom="sm"
            size="sm"
          />
          {/* Additional header content can be added here */}
        </Group>
      </AppShell.Header>

      {/* Navbar Section */}
      <AppShell.Navbar
        p={!opened ? 'md' : '0'}
        bg={opened ? 'white' : 'fnsidebarblue'}
      >
        <Box
          hidden={opened}
          style={{ display: 'flex', flexDirection: 'column', height: opened ? '0%' : '100%' }}
        >
          {/* Top Section of Navbar */}
          <Box
            style={{
              height: opened ? '0px' : '140px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Grid align="center">
              {/* First column with fixed width */}
              <Grid.Col
                hidden={opened}
                span="content"
              >
                <Center>
                  <NewChatButton onClick={setNewChat} />
                </Center>
              </Grid.Col>

              {/* Second column takes the remaining space */}
              <Grid.Col
                hidden={opened}
                span="auto"
              >
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Center>
                    <Tooltip
                      label="Collapse Sidebar"
                      color="black"
                    >
                      <SideBarIcon onClick={toggle} />
                    </Tooltip>
                  </Center>
                </Box>
              </Grid.Col>
            </Grid>

            <Text
              hidden={opened}
              fw={600}
              size={'16px'}
              mt={36}
              mb={24}
            >
              Chat History
            </Text>
          </Box>

          {/* Sessions List */}
          <Box style={{ flexGrow: 1, overflowY: 'auto' }}>
            <NavBarSessions
              sessions={sessions}
              sessionsLoading={false}
              loadChat={loadChat}
              newChat={setNewChat}
            />
          </Box>

          {/* Powered By Section */}
          <Box
            style={{
              height: '90px',
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
            }}
          >
            <img
              style={{ maxWidth: '96px', display: opened ? 'none' : 'inline' }}
              src={powered}
              alt="powered by"
            ></img>
          </Box>
        </Box>

        {/* Mobile Navbar or Collapsed Sidebar */}
        <Box
          h={'100%'}
          hidden={!opened}
        >
          {isMobile ? (
            <MobileNav
              setNewChat={setNewChat}
              open={opened}
              toggle={toggle}
              sessions={sessions}
              sessionsLoading={false}
              loadChat={loadChat}
            />
          ) : (
            <Stack
              w={'60px'}
              h={'100%'}
              justify="flex-start"
              align="center"
              mt={14}
              ml={4}
            >
              <Paper
                w={'60px'}
                radius={'md'}
                bg={'#F5F9FF'}
                pt={'md'}
                pb={'md'}
                ml="12"
              >
                <Stack
                  gap={'0px'}
                  w={'60px'}
                  justify="center"
                  align="center"
                >
                  <Tooltip
                    label="Expand Sidebar"
                    position="right"
                    color="black"
                  >
                    <SideBarIcon onClick={toggle} />
                  </Tooltip>
                  <Tooltip
                    label="New Chat"
                    position="right"
                    color="black"
                  >
                    <PencilSquareIcon onClick={setNewChat} />
                  </Tooltip>
                </Stack>
              </Paper>
            </Stack>
          )}
        </Box>
      </AppShell.Navbar>

      {/* Main Chat Area */}
      <AppShell.Main>
        <Stack style={{ height: `${divHeight}px` }}>
          <ChatHeader />
          {/* Loading Indicator */}
          {loading ? (
            <div style={{ height: '100%' }}>
              <Center style={{ height: '100%' }}>
                <LottieAnimation
                  animation="square"
                  style={{ width: '150px', height: '150px' }}
                />
              </Center>
            </div>
          ) : (
            <>
              {/* Chat Messages or Empty Chat Prompt */}
              {messages.length > 0 ? (
                <ChatContent
                  messages={messages}
                  loading={modeLoading}
                />
              ) : (
                <EmptyChat handleSend={handleInputFromUser} />
              )}

              {/* User Input Box */}
              <ChatBox handleSend={handleInputFromUser} />
            </>
          )}
        </Stack>
      </AppShell.Main>
    </AppShell>
  );
}
