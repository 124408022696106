import { Avatar, Divider, Grid, NavLink, Paper, Skeleton } from '@mantine/core';

import ModelSelect from './ModelSelect';

import { User } from '@supabase/supabase-js';
import { useEffect, useRef, useState } from 'react';
import { supabase } from '../../supabase';

import { UserService } from '@/utility/UserService';
import { useMediaQuery } from '@mantine/hooks';
import LogOutIcon from '../Icons/LogOutIcon';
import classes from './ChatHeader.module.css';

export function ChatHeader() {
  const [avatar, setAvatar] = useState<{ val: string; isUrl: boolean } | null>({
    val: '??',
    isUrl: false,
  });
  const [user, setUser] = useState<User | null>(null);
  const [showCard, setShowCard] = useState<boolean>(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleClickOutside = (event: MouseEvent) => {
    if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
      setShowCard(false);
    }
  };

  useEffect(() => {
    if (showCard) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showCard]);

  const logout = async (): Promise<void> => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error logging out:', error.message);
    } else {
    }
  };

  const handleLogout = async () => {
    await logout();
    // You might want to redirect the user or update your UI here
  };

  useEffect(() => {
    const userService = UserService.getInstance();

    async function loadUser() {
      const user = await userService.getUser();
      if (user) {
        setUser(user);
        const avatarData = userService.getAvatar();
        setAvatar(avatarData);
      }
    }

    loadUser();
  }, [user]);

  return (
    <>
      <Grid justify={isMobile ? 'flex-end' : 'space-between'}>
        <Grid.Col span="content">
          <ModelSelect />
        </Grid.Col>
        {/* <Grid.Col span="auto">d</Grid.Col> */}
        {isMobile ? (
          <></>
        ) : (
          <Grid.Col
            span="content"
            onClick={() => {
              setShowCard(true);
            }}
            style={{ cursor: 'pointer' }}
          >
            {!avatar || avatar.val === '??' ? (
              <>
                <Skeleton
                  height={'48px'}
                  circle
                  mb="xl"
                />
              </>
            ) : (
              <>
                {avatar.isUrl ? (
                  <>
                    <Avatar
                      src={avatar.val}
                      alt="it's me"
                    />
                  </>
                ) : (
                  <>
                    <Avatar
                      color="cyan"
                      radius="xl"
                    >
                      {avatar.val}
                    </Avatar>
                  </>
                )}
              </>
            )}

            <div style={{ position: 'relative', marginTop: '2px' }}>
              {showCard && (
                <Paper
                  withBorder
                  ref={cardRef}
                  shadow="md"
                  style={{
                    zIndex: 99999,
                    width: '224px',
                    position: 'absolute',
                    top: '0',
                    left: '-190px',
                    borderRadius: '8px',
                  }}
                >
                  <div
                    style={{
                      cursor: 'default',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '8px',
                    }}
                  >
                    {!avatar || avatar.val === '??' ? (
                      <Skeleton
                        height={'46px'}
                        circle
                        mb="xl"
                      />
                    ) : (
                      <>
                        {avatar.isUrl ? (
                          <Avatar
                            size={48}
                            src={avatar.val}
                            alt="it's me"
                            w={48}
                            h={48}
                          />
                        ) : (
                          <Avatar
                            size={48}
                            color="cyan"
                            radius="xl"
                          >
                            {avatar.val}
                          </Avatar>
                        )}
                      </>
                    )}
                  </div>
                  {user?.user_metadata?.name ? (
                    <span className={classes.name}>{user?.user_metadata?.name ?? ' '}</span>
                  ) : (
                    <></>
                  )}
                  <div>
                    <span className={classes.address}>
                      {user?.email
                        ?.replace(/@nr\.function\.network$/, '')
                        .replace(/^(.{13}).*?(.{13})$/, '$1...$2') ?? 'Function User'}
                    </span>
                  </div>
                  <Divider />
                  <NavLink
                    leftSection={<LogOutIcon />}
                    key={'logoutNavBarClick192897892yd'}
                    label={'Log-Out'}
                    onClick={handleLogout}
                  />
                </Paper>
              )}
            </div>
          </Grid.Col>
        )}
      </Grid>
    </>
  );
}
