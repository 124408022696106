import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@rainbow-me/rainbowkit/styles.css';
import { useEffect, useState } from 'react';
import { Router } from './Router';
import { theme } from './theme';

import { supabase } from '@/supabase';
import { Notifications } from '@mantine/notifications';
import { connectorsForWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { coinbaseWallet, metaMaskWallet, phantomWallet } from '@rainbow-me/rainbowkit/wallets';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createConfig, http, WagmiProvider } from 'wagmi';
import { base } from 'wagmi/chains';

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [coinbaseWallet, metaMaskWallet, phantomWallet],
    },
  ],
  {
    appName: 'Function Network',
    projectId: import.meta.env.VITE_PUBLIC_ETH_PROJECT_ID!,
  }
);

export const config = createConfig({
  chains: [base],

  connectors,
  transports: {
    [base.id]: http(),
  },
});

export interface SupabaseAuthSession {
  access_token: string;
  refresh_token?: string;
  user: {
    id: string;
    aud: string;
    role?: string;
    email?: string;
    phone?: string;
    created_at: string;
    confirmed_at?: string;
    last_sign_in_at?: string;
  };
  expires_in?: number;
  expires_at?: number;
  token_type?: string;
}

const queryClient = new QueryClient();
export default function App() {
  const [session, setSession] = useState<SupabaseAuthSession | null>(null);
  const [authLoading, setAuthAuthLoading] = useState(true); // Track authLoading state

  useEffect(() => {
    // Get the session on initial load
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setAuthAuthLoading(false); // Set authLoading to false after fetching session
    });

    // Listen for auth state changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    // Cleanup subscription on component unmount
    return () => subscription.unsubscribe();
  }, []);

  // Show a authLoading state while initializing
  if (authLoading) {
    return <div></div>;
  }

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <MantineProvider theme={theme}>
            <Notifications />
            <Router session={session} />
          </MantineProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}
