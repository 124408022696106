import React, { forwardRef, MouseEventHandler } from 'react';
import classes from './SideBarIcon.module.css';

interface SideBarIconProps extends React.HTMLAttributes<HTMLDivElement> {
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const SideBarIcon = forwardRef<HTMLDivElement, SideBarIconProps>((props, ref) => (
  <div
    ref={ref}
    {...props}
  >
    <span className={classes.iconwrapper}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        preserveAspectRatio="xMidYMid meet"
      >
        <rect
          x="3"
          y="3"
          width="18"
          height="18"
          rx="2"
          ry="2"
        />
        <line
          x1="9"
          y1="3"
          x2="9"
          y2="21"
        />
      </svg>
    </span>
  </div>
));

export default SideBarIcon;
