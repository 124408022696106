import useModelStore from '@/store/modelStore';
import { LLMModel, supaBaseFetchModels } from '@/utility/Utilities';
import { Button, NavLink, Paper, ScrollArea, Skeleton } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useRef, useState } from 'react';
import ChevronDownIcon from '../Icons/ChevronDownIcon';

const ModelSelect: React.FC = () => {
  const [showCard, setShowCard] = useState<boolean>(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const [models, setModels] = useState<LLMModel[]>([]);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { selectedModel, setSelectedModel } = useModelStore();

  useEffect(() => {
    async function loadModels() {
      const modelRsp = await supaBaseFetchModels();
      setModels(modelRsp);
      // Populate selected model once if it isn't populated before
      // Or if the model list no longer has the previously selected model.
      if (
        modelRsp.length > 0 &&
        (!selectedModel || !modelRsp.findIndex((s) => s.id === selectedModel.id))
      ) {
        setSelectedModel(modelRsp[0]);
      }
    }
    loadModels();
  }, []);

  const handleAddCard = () => {
    setShowCard(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
      setShowCard(false);
    }
  };

  useEffect(() => {
    if (showCard) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showCard]);

  function setModelAndClose(model: LLMModel): void {
    setSelectedModel(model);
    setShowCard(false);
  }

  if (models.length === 0) {
    return (
      <>
        <Skeleton
          height={'30px'}
          width={'117px'}
          radius="xl"
        />
      </>
    );
  }

  return (
    <div style={{ flex: '0 0 56px' }}>
      <Button
        c="black"
        variant="subtle"
        style={{ fontSize: '20px' }}
        color="#7d97cb"
        onClick={handleAddCard}
        rightSection={<ChevronDownIcon />}
      >
        {selectedModel?.name || 'Loading...'}
      </Button>

      <div style={{ position: 'relative', marginTop: '2px' }}>
        {showCard && (
          <Paper
            withBorder
            p={'xs'}
            ref={cardRef}
            shadow="sm"
            style={{
              zIndex: 99999,
              width: '300px',
              position: 'absolute',
              top: '0',
              maxHeight: '600px',
              overflow: 'auto',
              left: isMobile ? '-130px' : '0px',
            }}
          >
            <ScrollArea>
              {models.map((model) => (
                <NavLink
                  style={{ cursor: 'pointer' }}
                  key={model.id}
                  label={model.name}
                  // rightSection={<IconChevronRight size="0.8rem" stroke={1.5} className="mantine-rotate-rtl" />}
                  onClick={() => setModelAndClose(model)}
                  description={model.description}
                />
              ))}
            </ScrollArea>
          </Paper>
        )}
      </div>
    </div>
  );
};

export default ModelSelect;
