import { Box, Button, Center, Stack, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation
import checkMarkYes from '../assets/checkmarkyes.png';
import classes from './VerificationEmailSent.module.css';

export function VerificationEmailSent() {
  const navigate = useNavigate();

  return (
    <Center style={{ height: '100vh' }}>
      <Box
        style={{
          width: 405,
          textAlign: 'center',
        }}
      >
        <Stack align="center">
          {/* First Row: CheckMark Icon */}
          <img
            src={checkMarkYes}
            alt="Checkmark"
          />

          <Text className={classes.mainText}>Verification Email Sent!</Text>

          <Text className={classes.subText}>Please check your inbox to confirm</Text>

          <Button
            variant="solid"
            bg={'fnchatblue'}
            onClick={() => navigate(-1)} // Go back to the previous page
          >
            Go back
          </Button>
        </Stack>
      </Box>
    </Center>
  );
}
