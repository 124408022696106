import { Box } from '@mantine/core';
import React, { forwardRef, MouseEventHandler } from 'react';
import classes from './SideBarIcon.module.css';

interface PencilSquareIconProps extends React.HTMLAttributes<HTMLDivElement> {
  onClick?: MouseEventHandler<HTMLDivElement>;
  iconOnly?: boolean;
  size?: number;
}

const PencilSquareIcon = forwardRef<HTMLDivElement, PencilSquareIconProps>((props, ref) => {
  const size = props.size ?? 20;

  if (props.iconOnly) {
    return (
      <Box
        w={size}
        h={size}
        pl={3}
        {...props}
      >
        <svg
          width={size}
          height={size}
          color="black"
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
          />
        </svg>
      </Box>
    );
  }

  return (
    <div
      ref={ref}
      {...props}
    >
      <span className={classes.iconwrapper}>
        <svg
          width={size}
          height={size}
          color="black"
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
          />
        </svg>
      </span>
    </div>
  );
});

export default PencilSquareIcon;
