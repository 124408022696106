import Lottie from 'lottie-react';
import React from 'react';
import square from '../assets/Square Loading (Optimized).json';

interface LottieAnimationProps {
  loop?: boolean;
  autoplay?: boolean;
  style?: React.CSSProperties;
  animation: string;
}

const animationMap: any = {
  square: square,
  dots: square,
};

const LottieAnimation: React.FC<LottieAnimationProps> = ({
  loop = true,
  autoplay = true,
  style,
  animation,
}) => {
  return (
    <Lottie
      animationData={animationMap[animation]}
      loop={loop}
      autoplay={autoplay}
      style={style}
    />
  );
};

export default LottieAnimation;
