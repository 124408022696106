import { colorsTuple, createTheme } from '@mantine/core';

export const theme = createTheme({
  colors: {
    fngray: colorsTuple('#FAFAFD'),
    fnblue: colorsTuple('#F5F7FB'),
    fndarkblue: colorsTuple('#1C40FF'),
    fnchatblue: colorsTuple('#0425E2'),
    fnsidebarblue: colorsTuple('#F5F9FF'),
    fnsidebarlinkgray: colorsTuple('#848587'),
  },
  components: {
    Textarea: {
      styles: (theme: { colors: { fnblue: any[] } }) => ({
        input: {
          backgroundColor: theme.colors.fnblue[0], // use your custom color
          padding: '24px',
          paddingRight: '50px',
          border: '1px solid #D9E2EF',
        },
      }),
    },
    /** Put your mantine theme override here */
  },
});
